:host {
  --top-distance: 4rem;
  display: block;
  height: calc(100% - var(--top-distance));
  width: 100vw;
  max-width: 95vw;
  position: fixed;
  left: 0;
  bottom: 0;
  background: var(--gray-0);
  padding: 1rem 1rem 2.5rem;
  z-index: 50;
  transform: translate3d(-200%, 0, 0);
}

:host(.ready) {
  transition: all .4s var(--ease) 0s;
}

:host(.active) {
  transform: translate3d(0%, 0, 0);
}

:host([position="right"]) {
  left: auto;
  right: 0;
  transform: translate3d(200%, 0, 0);
}
:host([position="right"].active) {
  transform: translate3d(0%, 0, 0);
}

:host .panel-wrap {
  overflow: auto; 
  height: 100%;
  max-height: 100%;
  padding-bottom: 2rem;
}

:host ::slotted(.close-button) {
  position: absolute;
  top: 1rem;
  right: var(--close-button-position, 1rem);
  z-index: 10;
}

:host([invert]) {
  background: var(--gray-12);
}

:host([hide-close]) ::slotted(.close-button) {
  visibility: hidden;
}


@media (max-width: 750px) {
  :host {
    margin: 0 !important;
  }
}

@media (min-width: 600px) {
  :host {
    max-width: 28rem;
  }

  :host([wide]) {
    max-width: 90vw;
  }
}

@media (min-width: 750px) {
  :host {
    padding: 1rem;
  }
}

@media (min-width: 800px) {
  :host([wide]) {
    max-width: 70vw;
  }
}

@media (min-width: 1000px) {
  :host([wide]) {
    max-width: 50vw;
  }
}
