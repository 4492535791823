:host,
:host *,
:host *:before,
:host *:after {
  box-sizing: border-box;
  --color: var(--black);
}

:host {
  font-size: 1rem;
  --margin: 0.25em;
  line-height: 1.4;
  color: var(--color);
  display: block;
  font-weight: 700;
  letter-spacing: 0.025em !important;
  margin-bottom: var(--margin);
  cursor: pointer;
}

:host(.cursor-pointer) {
  cursor: pointer;
}

:host([hidden]) {
  visibility: hidden !important;
}

:host([underneath]) {
  margin-top: var(--margin);
  margin-bottom: 0;
  color: var(--gray-7);
  letter-spacing: 0.02em !important;
}

:host([size="small"]) {
  font-size: 0.75rem;
}

:host([size="large"]) {
  font-size: 1.25rem;
}

:host label {
  all: inherit;
  left: initial;
  right: initial;
  bottom: initial;
  top: initial;
  margin: auto;
  box-shadow: none;
}

:host([dark]) {
  color: var(--gray-0);
}

:host([underneath][dark]) {
  color: var(--gray-5);
}
